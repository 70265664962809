import React from "react";
import { Link } from "react-router-dom";

export default function PortfolioOfTheWeek({ card }) {
  const slug = card.slug;
  const date = new Date(card.Date);
  const time = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;

  return (
    <Link to={`/portfolio/${slug}`} className="block">
      <div className="bg-[#f5f1e4] w-full max-w-4xl mx-auto h-full border-8 p-2 sm:p-8 font-serif">
        <h1 className="text-lg sm:text-4xl mb-8 font-bold text-[#333]">Portfolio of the Week</h1>
        <div className="sm:flex mb-6">
          <img
            src={card.banner}
            alt={card.title}
            className="border rounded-md w-full sm:w-64 h-64 sm:h-56 object-cover"
          />
          <div className="mt-4 sm:mt-0 sm:ml-3 flex flex-col flex-1">
          <span className="bg-[#ffeb3b] px-4 py-2 text-sm sm:text-base font-sans self-start mb-4">
              {card.category}
            </span>

            <h2 className="text-lg sm:text-xl font-bold mb-4">{card.title}</h2>
            {/* <p className="text-base sm:text-lg text-gray-700 mb-4 flex-grow">{card.about}</p> */}
          </div>
        </div>
            <span className="text-blue-600 hover:underline text-lg">Read More</span>
      </div>
    </Link>
  );
}